import { inject, observable } from 'aurelia-framework';

const moment = require("moment");
import { BasicForm } from "../../resources/elements/BasicForm";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "../../resources/services/ConfigService";
import { PatientService } from 'resources/services/PatientService';
import * as environment from "../../../config/environment.json";
import { FhirService } from "../../resources/services/FhirService";
import { UserService } from "../../resources/services/UserService";

@inject(I18N, PatientService, UserService)
export class PatientCurve {
    @observable dateFrom;
    @observable dateTo;
    @observable dateFromComponents;
    @observable dateToComponents;
    @observable patient;
    patientService: PatientService;
    i18n: I18N;
    userService: UserService;
    config;
    iframeWrapper;
    medicationsVisible: boolean;
    encounterId: string = undefined;
    container;
    vitalAxes = 0;
    kurveEnabled = false
    iframeUrl = ''
    iframeContainer
    tokenUpdateListenerRemove

    constructor(i18n: I18N, patientService: PatientService, userService: UserService) {
        this.patientService = patientService;
        this.i18n = i18n;
        this.userService = userService;
    }

    async activate(params) {
        this.encounterId = params.id;
    }

    async deactivate() {
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
        document.body.classList.remove("hide-process-arrows");

        this.tokenUpdateListenerRemove();
    }

    async attached() {
        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.Curve);
        this.patient = await this.patientService.fetch(this.encounterId);

        const loginData = sessionStorage.getItem(environment.sessionName);

        await this.userService.forceRefreshToken();
        this.tokenUpdateListenerRemove = this.userService.addTokenUpdateListener(this.sendNewToken.bind(this));

        const oauth = sessionStorage.getItem(environment.oauth2SessionName);

        this.kurveEnabled = this.config.settings && this.config.settings.iframe && this.config.settings.iframe.enabled

        const query = {
            login: loginData,
            oauth: oauth,
            server: FhirService.Endpoint,
            origin: window.location.origin,
            encounterId: this.patient.encounterId,
            patientId: this.patient.id,
            practitionerId: '',
            practitionerName: ''
        };

        if (this.userService.practitioner) {
            query.practitionerId = this.userService.practitioner.id;
            query.practitionerName = this.userService.fullNameOrUsername
        }

        this.iframeUrl = `${this.config.settings && this.config.settings.iframe && this.config.settings.iframe.url}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`
        }).join('&')

        if (this.config) {
            BasicForm.pageTitle = this.i18n.tr(this.config.title);
        }

        this.medicationsVisible = this.config && this.config.settings["showMedications"];
        document.body.classList.add("no-toolbar-window");
        if (UserService.UserRole === "doctor")
            document.body.classList.add("hide-process-arrows");

        if (this.patient && this.patient.encounter && this.patient.encounter.period && this.patient.encounter.period.start && this.patient.encounter.period.end) {
            this.dateFrom = moment(this.patient.encounter.period.start).startOf('day').toDate();
            this.dateTo = moment(this.patient.encounter.period.start).startOf('day').add(6, 'days').toDate();
        } else {
            this.dateFrom = moment().endOf('day').subtract(6, 'days').toDate();
            this.dateTo = moment().endOf('day').toDate();
        }

        this.dateFromComponents = this.dateFrom;
        this.dateToComponents = this.dateTo;
    }

    sendNewToken() {
        if (this.iframeContainer) {
            try {
                this.iframeContainer.contentWindow.postMessage({
                    name: 'update-token',
                    data: sessionStorage.getItem(environment.oauth2SessionName)
                }, this.iframeUrl);
            } catch (e) {
            }
        }
    }

    dateFromChanged(val) {
        this.dateFromComponents = val;
    }

    dateToChanged(val) {
        this.dateToComponents = val;
    }

    axesChanged(val) {
        this.vitalAxes = val;
    }
}
